let brush, timeScale, timeline, canvas, context;
let points;
let x, y;
let gDot;
let currentTransform = d3.zoomIdentity;

const MAX_POINTS = 20000; // Adjust this value based on performance tests

// Filter state
let filterState = {
  topicArea: new Set(),
  sentiment: new Set(),
  offensive: new Set(),
  language: new Set(),
  emotion: new Set(),
  irony: new Set(),
  hate: new Set(),
  dateRange: {
    start: new Date('2020-01-11'),
    end: new Date('2021-04-02')
  }
};

// Store original state for reset
const originalState = {
  topicArea: new Set(),
  sentiment: new Set(),
  offensive: new Set(),
  language: new Set(),
  emotion: new Set(),
  irony: new Set(),
  hate: new Set(),
  dateRange: {
    start: new Date('2020-01-11'),
    end: new Date('2021-04-02')
  }
};

// Grouping state
let groupingLevel = 1;
const maxGroupingLevel = 5;

let currentDateRange = { start: null, end: null };
let currentSearchTerm = "";
let currentColorColumn = "Topic";

let searchTerms = [];
let searchColors = [];

const sentimentColors = [
  "#e63946", // Rich Red
  "#0077b6", // Strong Blue
  "#4caf50", // Lively Green
];

const offensiveColors = [
  "#8d99ae", // Dusty Gray-Blue
  "#e63946", // Rich Red
];

const customPastelColors = [
  "#2ec4b6", // Bright Teal
  "#ffbf69", // Vibrant Yellow
  "#9d4edd", // Vivid Purple
  "#ff6f61", // Bold Coral
  "#0096c7", // Deep Sky Blue
  "#ffbe0b", // Bright Orange
  "#4caf50", // Lively Green
  "#ff82c6", // Hot Pink
  "#d3d3d3", // Light Gray (for neutral contrast)
  "#ab47bc", // Rich Lavender
  "#8bc34a", // Bright Lime Green
  "#ffd700", // Golden Yellow
  "#00b4d8", // Bright Cyan
  "#0077b6", // Strong Blue
  "#a8dadc", // Soft Blue-Green
  "#1d3557", // Deep Navy
  "#ff4b5c", // Bright Red
  "#e63946", // Rich Red
  "#f77f00", // Burnt Orange
  "#e07a5f", // Warm Salmon
  "#3a0ca3", // Strong Purple
  "#ffafcc", // Light Pink
  "#457b9d", // Muted Blue
  "#8d99ae", // Dusty Gray-Blue
];

// Add new emotion colors
const emotionColors = {
  "anticipation": "#9d4edd", // Purple
  "anger": "#e63946",       // Red
  "joy": "#4caf50",        // Green
  "fear": "#ff9800",       // Orange
  "disgust": "#795548",    // Brown
  "neutral": "#9e9e9e"      // Gray
};

// Helper function to format dates as DD/MM/YY
function formatDate(date) {
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, '0');
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const year = d.getFullYear().toString().slice(-2);
  return `${day}/${month}/${year}`;
}

function isMobileDevice() {
  // Check user agent string for mobile devices
  return /Mobi|Android|iPhone|iPod|BlackBerry|Windows Phone/i.test(
    navigator.userAgent
  );
}

function showMobileMessage() {
  const mobileMessage = document.getElementById("mobile-message");
  if (mobileMessage) {
    mobileMessage.style.display = "flex";
  }
}

function formatDateForHeader(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().substr(-2);
  return `${day}/${month}/${year}`;
}

function updateDateRangeDisplay(start, end) {
  const displayElement = d3.select("#date-range-display");
  const startFormatted = formatDate(start);
  const endFormatted = formatDate(end);
  displayElement.text(`${startFormatted} - ${endFormatted}`);
}

// Helper function to extract text between underscores
function processTopicName(name) {
  if (!name) return '';
  const matches = name.match(/_([^_]+)_/);
  return matches ? matches[1] : name;
}

async function loadCSV() {
  if (isMobileDevice()) {
    showMobileMessage();
    return;
  }

  // Show loading element
  document.getElementById("loading").style.display = "flex";

  try {
    // Fetch data from your API endpoint
    const response = await fetch("/api/sites");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    window.data = responseData;

    // Initialize visualization
    const { svgNode, points: createdPoints, xScale, yScale } = run(responseData);
    
    // Set global variables
    points = createdPoints;
    x = xScale;
    y = yScale;

    // Update stats panel and initialize filters
    updateStatsPanel(responseData);
    initializeFilters();
    populateDropdowns(responseData);

  } catch (error) {
    console.error("There was a problem fetching the data:", error);
  } finally {
    document.getElementById("loading").style.display = "none";
  }
}

function run(data) {
  //Setting Variables
  let width = window.innerWidth;
  let height = window.innerHeight;
  let k = height / width;

  const app = d3.select("#app");
  const svgContainer = d3.select("#svg-container");

  //Setting up the scales
  x = d3.scaleLinear().domain([-18, 26]).range([0, width]);
  y = d3.scaleLinear().domain([-17, 27]).range([height, 0]);

  const svg = svgContainer.append("svg")
    .attr("viewBox", [0, 0, width, height])
    .attr("width", "100%")
    .attr("height", "100%")
    .style("position", "fixed")
    .style("top", "0")
    .style("left", "0");

  const gGrid = svg.append("g");

  //Setting up the Grid
  const grid = (g, x, y) =>
    g
      .attr("stroke", "white")
      .attr("stroke-opacity", 0.16)
      .call((g) =>
        g
          .selectAll(".x")
          .data(x.ticks(12))
          .join(
            (enter) =>
              enter.append("line").attr("class", "x").attr("y2", height),
            (update) => update,
            (exit) => exit.remove()
          )
          .attr("x1", (d) => 0.5 + x(d))
          .attr("x2", (d) => 0.5 + x(d))
      )
      .call((g) =>
        g
          .selectAll(".y")
          .data(y.ticks(12 * k))
          .join(
            (enter) =>
              enter.append("line").attr("class", "y").attr("x2", width),
            (update) => update,
            (exit) => exit.remove()
          )
          .attr("y1", (d) => 0.5 + y(d))
          .attr("y2", (d) => 0.5 + y(d))
      );

  //Setting up the Axes (removing ticks and numbers)
  const xAxis = (g, x) =>
    g
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisTop(x).ticks(0))
      .call((g) => g.select(".domain").attr("display", "none"));

  const yAxis = (g, y) =>
    g
      .call(d3.axisRight(y).ticks(0))
      .call((g) => g.select(".domain").attr("display", "none"));

  //Creating the scatterplot on the grid
  gDot = svg
    .append("g")
    .attr("fill", "none")
    .attr("stroke-linecap", "round");

  // Initial points creation
  points = gDot
    .selectAll(".point")
    .data(data, d => `${d.Embeddings_umap_x}-${d.Embeddings_umap_y}-${d.Emotion}`)
    .join("path")
    .attr("class", "point")
    .attr("d", (d) => `M${x(d.Embeddings_umap_x)},${y(d.Embeddings_umap_y)}h0`)
    .attr("opacity", 1)
    .attr("stroke", d => emotionColors[d.Emotion.toLowerCase()] || "#9e9e9e")
    .attr("data-original-color", d => emotionColors[d.Emotion.toLowerCase()] || "#9e9e9e");

  const gx = svg.append("g");
  const gy = svg.append("g");

  //Setting up the zoom
  let currentTransform = d3.zoomIdentity;

  const zoom = d3.zoom()
    .scaleExtent([1, 32])
    .on("zoom", zoomed);

  svg.call(zoom).call(zoom.transform, d3.zoomIdentity);

  function zoomed({ transform }) {
    currentTransform = transform;
    const zx = transform.rescaleX(x).interpolate(d3.interpolateRound);
    const zy = transform.rescaleY(y).interpolate(d3.interpolateRound);

    // Update grid and axes
    gx.call(xAxis, zx);
    gy.call(yAxis, zy);
    gGrid.call(grid, zx, zy);

    // Update points with transform
    gDot.attr("transform", transform)
        .attr("stroke-width", 5 / transform.k);
  }

  //Setting up the resize
  function onWindowResize() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const k = height / width;

    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);

    x.range([0, width]);
    y.range([height, 0]);

    const zx = currentTransform.rescaleX(x).interpolate(d3.interpolateRound);
    const zy = currentTransform.rescaleY(y).interpolate(d3.interpolateRound);

    gx.call(xAxis, zx).attr("transform", `translate(0,${height})`);
    gy.call(yAxis, zy);

    gGrid.selectAll(".x").attr("y2", height);
    gGrid.selectAll(".y").attr("x2", width);
    gGrid.call(grid, zx, zy);

    // Update points
    gDot.selectAll(".point")
        .attr("d", d => `M${x(d.Embeddings_umap_x)},${y(d.Embeddings_umap_y)}h0`);

    svg.call(zoom.transform, currentTransform);
  }

  window.addEventListener("resize", onWindowResize);

  return {
    svgNode: svg.node(),
    points: points,
    xScale: x,
    yScale: y,
    zoom: zoom,
  };
}

function populateDropdowns(data) {
  console.log('Populating dropdowns with data:', data?.length);
  if (!data || !Array.isArray(data)) {
    console.error('No data available for populating dropdowns');
    return;
  }

  // Define the mapping between dropdown IDs and data fields
  const dropdowns = [
    { id: 'topic-area', field: 'Name', processor: processTopicName },  // Changed from 'Topic' to 'Name' with processor
    { id: 'sentiment', field: 'Sentiment' },
    { id: 'offensive', field: 'Offensive' },
    { id: 'language', field: 'Language' },
    { id: 'emotion', field: 'Emotion' },
    { id: 'irony', field: 'Irony' },
    { id: 'hate', field: 'Hate' }
  ];

  dropdowns.forEach(({ id, field, processor }) => {
    // Get unique values for this field, applying processor if available
    const uniqueValues = [...new Set(data.map(item => processor ? processor(item[field]) : item[field]))].filter(Boolean).sort();
    console.log(`Unique values for ${field}:`, uniqueValues);

    // Get the dropdown element
    const dropdownContent = document.getElementById(`${id}-options`);
    if (!dropdownContent) {
      console.warn(`Dropdown not found: ${id}-options`);
      return;
    }

    // Clear existing content
    dropdownContent.innerHTML = '';

    // Create the container for checkboxes
    const container = document.createElement('div');
    container.className = 'py-1';

    // Add checkboxes for each unique value
    uniqueValues.forEach(value => {
      const label = document.createElement('label');
      label.className = 'checkbox-container';
      
      const checkbox = document.createElement('input');
      checkbox.type = 'checkbox';
      checkbox.value = value;
      checkbox.checked = false; // Start with no filters selected
      
      const span = document.createElement('span');
      span.textContent = value;
      
      label.appendChild(checkbox);
      label.appendChild(span);
      container.appendChild(label);

      // Initialize filter state as empty
      const filterKey = id.replace(/-([a-z])/g, g => g[1].toUpperCase());
      filterState[filterKey] = new Set();
      originalState[filterKey] = new Set();
    });

    dropdownContent.appendChild(container);
  });
}

function applyFilters() {
  const selectedEmotions = Array.from(document.querySelectorAll('#emotion-options input:checked')).map(cb => cb.value);
  const selectedSentiments = Array.from(document.querySelectorAll('#sentiment-options input:checked')).map(cb => cb.value);
  const selectedTopics = Array.from(document.querySelectorAll('#topic-area-options input:checked')).map(cb => cb.value);
  const selectedOffensive = Array.from(document.querySelectorAll('#offensive-options input:checked')).map(cb => cb.value);
  const selectedLanguage = Array.from(document.querySelectorAll('#language-options input:checked')).map(cb => cb.value);
  const selectedIrony = Array.from(document.querySelectorAll('#irony-options input:checked')).map(cb => cb.value);
  const selectedHate = Array.from(document.querySelectorAll('#hate-options input:checked')).map(cb => cb.value);

  // Get date range values
  const startDateDisplay = document.getElementById('start-date-display').textContent;
  const endDateDisplay = document.getElementById('end-date-display').textContent;
  
  // Parse dates (assuming format DD/MM/YY)
  let startDate = null;
  let endDate = null;
  if (startDateDisplay) {
    const [day, month, year] = startDateDisplay.split('/');
    startDate = new Date(2000 + parseInt(year), parseInt(month) - 1, parseInt(day));
    startDate.setHours(0, 0, 0, 0);
  }
  if (endDateDisplay) {
    const [day, month, year] = endDateDisplay.split('/');
    endDate = new Date(2000 + parseInt(year), parseInt(month) - 1, parseInt(day));
    endDate.setHours(23, 59, 59, 999);
  }

  // Check if any filters are selected
  const hasFilters = [
    selectedEmotions,
    selectedSentiments,
    selectedTopics,
    selectedOffensive,
    selectedLanguage,
    selectedIrony,
    selectedHate
  ].some(arr => arr.length > 0) || startDate || endDate;

  // If no filters are selected, show all data
  if (!hasFilters) {
    console.log('No filters selected, showing all data');
    updateVisualization(window.data);
    return;
  }

  // Filter the data based on all selected criteria
  const filteredData = window.data.filter(d => {
    const emotionMatch = selectedEmotions.length === 0 || selectedEmotions.includes(d.Emotion);
    const sentimentMatch = selectedSentiments.length === 0 || selectedSentiments.includes(d.Sentiment);
    const topicMatch = selectedTopics.length === 0 || selectedTopics.includes(processTopicName(d.Name));  // Changed from d.Topic
    const offensiveMatch = selectedOffensive.length === 0 || selectedOffensive.includes(d.Offensive);
    const languageMatch = selectedLanguage.length === 0 || selectedLanguage.includes(d.Language);
    const ironyMatch = selectedIrony.length === 0 || selectedIrony.includes(d.Irony);
    const hateMatch = selectedHate.length === 0 || selectedHate.includes(d.Hate);
    
    // Date range match
    let dateMatch = true;
    if (startDate || endDate) {
      const tweetDate = new Date(d.datetime);
      if (startDate) {
        dateMatch = dateMatch && tweetDate >= startDate;
      }
      if (endDate) {
        dateMatch = dateMatch && tweetDate <= endDate;
      }
    }
    
    // AND between different categories
    return emotionMatch && 
           sentimentMatch && 
           topicMatch && 
           offensiveMatch && 
           languageMatch && 
           ironyMatch && 
           hateMatch &&
           dateMatch;
  });

  console.log('Applied filters:', {
    emotions: selectedEmotions,
    sentiments: selectedSentiments,
    topics: selectedTopics,
    offensive: selectedOffensive,
    language: selectedLanguage,
    irony: selectedIrony,
    hate: selectedHate,
    dateRange: {
      start: startDate,
      end: endDate
    }
  });
  
  console.log('Filtered data:', filteredData.length, 'points');
  updateVisualization(filteredData);
}

// Expose applyFilters to window object
window.applyFilters = applyFilters;

function updateStatsPanel(data) {
  if (!data || !Array.isArray(data)) {
    console.warn('No data available for stats panel update');
    return;
  }

  // Update total posts count
  const totalPosts = data.length;
  const totalPostsElement = document.querySelector('.stats-panel .panel-header .bg-neutral-400 .font-mono:last-child');
  if (totalPostsElement) {
    totalPostsElement.textContent = totalPosts.toLocaleString();
  }

  // Update date range
  const dates = data.map(d => new Date(d.datetime));
  const minDate = d3.min(dates);
  const maxDate = d3.max(dates);
  const dateRangeElement = document.querySelector('.stats-panel .panel-header .font-mono.text-white');
  if (dateRangeElement && minDate && maxDate) {
    dateRangeElement.textContent = `${formatDateForHeader(minDate)} - ${formatDateForHeader(maxDate)}`;
  }

  // Initialize statistics counters
  const stats = {
    topic: {},
    sentiment: {},
    offensive: {},
    language: {},
    emotion: {},
    irony: {},
    hate: {}
  };

  // Count occurrences
  data.forEach(d => {
    const processedName = processTopicName(d.Name);  // Process the Name field
    if (processedName) stats.topic[processedName] = (stats.topic[processedName] || 0) + 1;  // Changed from d.Topic
    if (d.Sentiment) stats.sentiment[d.Sentiment] = (stats.sentiment[d.Sentiment] || 0) + 1;
    if (d.Offensive) stats.offensive[d.Offensive] = (stats.offensive[d.Offensive] || 0) + 1;
    if (d.Language) stats.language[d.Language] = (stats.language[d.Language] || 0) + 1;
    if (d.Emotion) stats.emotion[d.Emotion] = (stats.emotion[d.Emotion] || 0) + 1;
    if (d.Irony) stats.irony[d.Irony] = (stats.irony[d.Irony] || 0) + 1;
    if (d.Hate) stats.hate[d.Hate] = (stats.hate[d.Hate] || 0) + 1;
  });

  // Update table cells
  const tbody = document.querySelector('.stats-panel table tbody tr');
  if (!tbody) return;

  // Helper function to create cell content
  function createCellContent(stats, category) {
    return Object.entries(stats)
      .sort(([,a], [,b]) => b - a)
      .map(([key, value]) => {
        const percentage = ((value / totalPosts) * 100).toFixed(1);
        if (category === 'emotion') {
          const color = emotionColors[key.toLowerCase()] || '#9e9e9e';
          return `
            <div class="py-0.5 flex items-center">
              <span class="h-2 w-2 rounded-full mr-2" style="background-color: ${color}"></span>
              ${key.toLowerCase()} (${percentage}%) ${value.toLocaleString()}
            </div>`;
        }
        return `<div class="py-0.5">${key.toLowerCase()} (${percentage}%) ${value.toLocaleString()}</div>`;
      })
      .join('');
  }

  // Update all cells
  const categories = ['topic', 'sentiment', 'offensive', 'language', 'emotion', 'irony', 'hate'];
  categories.forEach((category, index) => {
    const cell = tbody.querySelector(`td:nth-child(${index + 1})`);
    if (cell) {
      cell.innerHTML = createCellContent(stats[category], category);
    }
  });
}

function updateVisualization(filteredData) {
  console.log('Updating visualization with', filteredData.length, 'points');
  
  if (!gDot || !x || !y) {
    console.error('Required visualization components not initialized');
    return;
  }

  // Update points with filtered data using a key function
  const points = gDot
    .selectAll(".point")
    .data(filteredData, d => `${d.Embeddings_umap_x}-${d.Embeddings_umap_y}-${d.Emotion}`);

  // Remove points that are no longer in the data
  points.exit()
    .transition()
    .duration(500)
    .attr("opacity", 0)
    .remove();

  // Add new points
  const enterPoints = points.enter()
    .append("path")
    .attr("class", "point")
    .attr("d", d => `M${x(d.Embeddings_umap_x)},${y(d.Embeddings_umap_y)}h0`)
    .attr("stroke", d => emotionColors[d.Emotion.toLowerCase()] || "#9e9e9e")
    .attr("data-original-color", d => emotionColors[d.Emotion.toLowerCase()] || "#9e9e9e")
    .attr("opacity", 0)
    .on("click", function(event, d) {
      const sidePanel = d3.select("#side-panel");
      const dynamicContent = sidePanel.select(".dynamicContent");
      
      dynamicContent
        .html(
          `
          <h2 style="margin-bottom: 10px; font-family: var(--main-font); font-size: 22px;">Information</h2>
          <p><strong>Created At:</strong> ${d.datetime}</p>
          <p><strong>Text:</strong> ${d.Document}</p>
          <p><strong>Topic Area:</strong> ${processTopicName(d.Name)}</p>
          <p><strong>Sentiment:</strong> ${d.Sentiment}</p>
          <p><strong>Offensive:</strong> ${d.Offensive}</p>
          <p><strong>Language:</strong> ${d.Language}</p>
          <p><strong>Emotion:</strong> ${d.Emotion}</p>
          <p><strong>Irony:</strong> ${d.Irony}</p>
          <p><strong>Hate:</strong> ${d.Hate}</p>
        `
        )
        .style("line-height", "1.5")
        .style("font-family", "var(--main-font)")
        .style("font-size", "16px");

      sidePanel.style("display", "block");
    });

  // Update existing points and merge with new points
  points.merge(enterPoints)
    .transition()
    .duration(500)
    .attr("d", d => `M${x(d.Embeddings_umap_x)},${y(d.Embeddings_umap_y)}h0`)
    .attr("opacity", 1)
    .attr("stroke", d => emotionColors[d.Emotion.toLowerCase()] || "#9e9e9e");

  // Update stats panel
  updateStatsPanel(filteredData);
}

function initializeFilters() {
  console.log('Initializing filters');
  
  // Set up dropdown buttons
  document.querySelectorAll('.relative button').forEach(button => {
    const dropdown = button.parentElement.querySelector('.dropdown-content');
    if (!dropdown) return;

    button.addEventListener('click', (e) => {
      e.stopPropagation();
      
      // Close all other dropdowns
      document.querySelectorAll('.dropdown-content').forEach(other => {
        if (other !== dropdown) {
          other.style.display = 'none';
        }
      });

      // Toggle current dropdown
      const isVisible = dropdown.style.display === 'block';
      if (!isVisible) {
        const rect = button.getBoundingClientRect();
        dropdown.style.position = 'fixed';
        dropdown.style.top = `${rect.bottom + 2}px`;
        dropdown.style.left = `${rect.left}px`;
        dropdown.style.minWidth = `${rect.width}px`;
        dropdown.style.display = 'block';
      } else {
        dropdown.style.display = 'none';
      }
    });
  });

  // Close dropdowns when clicking outside
  document.addEventListener('click', (e) => {
    if (!e.target.closest('.dropdown-content') && !e.target.closest('button')) {
      document.querySelectorAll('.dropdown-content').forEach(dropdown => {
        dropdown.style.display = 'none';
      });
    }
  });

  // Initialize save button
  const saveButton = document.getElementById('save-button');
  if (saveButton) {
    saveButton.addEventListener('click', () => {
      console.log('Applying filters...');
      
      // Update filter state based on checked boxes
      document.querySelectorAll('.dropdown-content').forEach(dropdown => {
        const filterType = dropdown.id.replace('-options', '').replace(/-([a-z])/g, g => g[1].toUpperCase());
        filterState[filterType] = new Set();
        
        dropdown.querySelectorAll('input[type="checkbox"]:checked').forEach(checkbox => {
          filterState[filterType].add(checkbox.value);
        });
      });
      
      // Apply filters and update visualization
  applyFilters();
    });
  }

  // Initialize reset button
  const resetButton = document.getElementById('reset-button');
  if (resetButton) {
    resetButton.addEventListener('click', () => {
      console.log('Resetting filters...');
      
      // Uncheck all checkboxes
      document.querySelectorAll('.dropdown-content input[type="checkbox"]').forEach(checkbox => {
        checkbox.checked = false;
      });
      
      // Clear all filter states
      Object.keys(filterState).forEach(key => {
        if (key === 'dateRange') {
          filterState[key] = { ...originalState[key] };
        } else {
          filterState[key] = new Set();
        }
      });
      
      // Apply filters to show all data
      applyFilters();
    });
  }
}

// Add these functions after the populateDropdowns function
function initializeCalendars() {
  const startDateBtn = document.getElementById('start-date-button');
  const endDateBtn = document.getElementById('end-date-button');
  const startCalendar = document.getElementById('start-calendar');
  const endCalendar = document.getElementById('end-calendar');

  // Initialize calendar state
  let currentStartDate = new Date('2020-01-11');
  let currentEndDate = new Date('2021-04-02');
  let currentStartMonth = new Date(currentStartDate);
  let currentEndMonth = new Date(currentEndDate);

  // Update display
  document.getElementById('start-date-display').textContent = formatDate(currentStartDate);
  document.getElementById('end-date-display').textContent = formatDate(currentEndDate);

  // Toggle calendar visibility
  startDateBtn.addEventListener('click', (e) => {
    e.stopPropagation();
    const isVisible = startCalendar.classList.contains('show');
    hideAllCalendars();
    if (!isVisible) {
      startCalendar.classList.add('show');
      updateCalendar(startCalendar, currentStartMonth, currentStartDate, true);
    }
  });

  endDateBtn.addEventListener('click', (e) => {
    e.stopPropagation();
    const isVisible = endCalendar.classList.contains('show');
    hideAllCalendars();
    if (!isVisible) {
      endCalendar.classList.add('show');
      updateCalendar(endCalendar, currentEndMonth, currentEndDate, false);
    }
  });

  // Handle calendar navigation and date selection
  [startCalendar, endCalendar].forEach((calendar, index) => {
    const isStart = index === 0;
    const currentMonth = isStart ? currentStartMonth : currentEndMonth;

    calendar.querySelector('.prev-month').addEventListener('click', (e) => {
      e.stopPropagation();
      currentMonth.setMonth(currentMonth.getMonth() - 1);
      updateCalendar(calendar, currentMonth, isStart ? currentStartDate : currentEndDate, isStart);
    });

    calendar.querySelector('.next-month').addEventListener('click', (e) => {
      e.stopPropagation();
      currentMonth.setMonth(currentMonth.getMonth() + 1);
      updateCalendar(calendar, currentMonth, isStart ? currentStartDate : currentEndDate, isStart);
    });

    calendar.addEventListener('click', (e) => {
      e.stopPropagation();
      if (e.target.classList.contains('calendar-day') && !e.target.classList.contains('disabled')) {
        const selectedDate = new Date(e.target.dataset.date);
        if (isStart) {
          if (selectedDate <= currentEndDate) {
            currentStartDate = selectedDate;
            document.getElementById('start-date-display').textContent = formatDate(selectedDate);
            hideAllCalendars();
            applyFilters();
          }
        } else {
          if (selectedDate >= currentStartDate) {
            currentEndDate = selectedDate;
            document.getElementById('end-date-display').textContent = formatDate(selectedDate);
            hideAllCalendars();
            applyFilters();
          }
        }
      }
    });
  });

  // Close calendars when clicking outside
  document.addEventListener('click', () => {
    hideAllCalendars();
  });
}

function hideAllCalendars() {
  document.querySelectorAll('.calendar').forEach(cal => cal.classList.remove('show'));
}

function updateCalendar(calendar, currentMonth, selectedDate, isStart) {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
                     'July', 'August', 'September', 'October', 'November', 'December'];
  
  calendar.querySelector('.current-month').textContent = 
    `${monthNames[currentMonth.getMonth()]} ${currentMonth.getFullYear()}`;

  const daysGrid = calendar.querySelector('.days-grid');
  daysGrid.innerHTML = '';

  const firstDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
  const lastDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
  
  // Add empty cells for days before the first day of the month
  for (let i = 0; i < firstDay.getDay(); i++) {
    const emptyDay = document.createElement('div');
    emptyDay.className = 'calendar-day';
    daysGrid.appendChild(emptyDay);
  }

  // Add days of the month
  for (let day = 1; day <= lastDay.getDate(); day++) {
    const dayElement = document.createElement('div');
    dayElement.className = 'calendar-day';
    dayElement.textContent = day;
    
    const currentDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
    dayElement.dataset.date = currentDate.toISOString();

    // Add selected class if this is the currently selected date
    if (currentDate.toDateString() === selectedDate.toDateString()) {
      dayElement.classList.add('selected');
    }

    // Add today class if this is today
    if (currentDate.toDateString() === new Date().toDateString()) {
      dayElement.classList.add('today');
    }

    // Disable dates based on start/end date constraints
    if (isStart && currentDate > window.data[window.data.length - 1].datetime) {
      dayElement.classList.add('disabled');
    } else if (!isStart && currentDate < new Date(window.data[0].datetime)) {
      dayElement.classList.add('disabled');
    }

    daysGrid.appendChild(dayElement);
  }
}

// Update the initialization to include calendar setup
document.addEventListener('DOMContentLoaded', () => {
  loadCSV();
  initializeCalendars();
});
