let brush, timeScale, timeline;
let points;
let x, y;

let currentDateRange = { start: null, end: null };
let currentSearchTerm = "";
let currentColorColumn = "Topic";

//This is a test

let searchTerms = [];
let searchColors = [];

const sentimentColors = [
  "#e63946", // Rich Red
  "#0077b6", // Strong Blue
  "#4caf50", // Lively Green
];

const offensiveColors = [
  "#8d99ae", // Dusty Gray-Blue
  "#e63946", // Rich Red
];

const customPastelColors = [
  "#2ec4b6", // Bright Teal
  "#ffbf69", // Vibrant Yellow
  "#9d4edd", // Vivid Purple
  "#ff6f61", // Bold Coral
  "#0096c7", // Deep Sky Blue
  "#ffbe0b", // Bright Orange
  "#4caf50", // Lively Green
  "#ff82c6", // Hot Pink
  "#d3d3d3", // Light Gray (for neutral contrast)
  "#ab47bc", // Rich Lavender
  "#8bc34a", // Bright Lime Green
  "#ffd700", // Golden Yellow
  "#00b4d8", // Bright Cyan
  "#0077b6", // Strong Blue
  "#a8dadc", // Soft Blue-Green
  "#1d3557", // Deep Navy
  "#ff4b5c", // Bright Red
  "#e63946", // Rich Red
  "#f77f00", // Burnt Orange
  "#e07a5f", // Warm Salmon
  "#3a0ca3", // Strong Purple
  "#ffafcc", // Light Pink
  "#457b9d", // Muted Blue
  "#8d99ae", // Dusty Gray-Blue
];

function isMobileDevice() {
  // Check user agent string for mobile devices
  return /Mobi|Android|iPhone|iPod|BlackBerry|Windows Phone/i.test(
    navigator.userAgent
  );
}

function showMobileMessage() {
  const mobileMessage = document.getElementById("mobile-message");
  if (mobileMessage) {
    mobileMessage.style.display = "flex";
  }
}

function formatDate(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().substr(-2);

  const day = date.getDate().toString().padStart(2, "0");
  return `${day} ${month} ${year}'`;
}
function updateDateRangeDisplay(start, end) {
  const displayElement = d3.select("#date-range-display");
  const startFormatted = formatDate(start);
  const endFormatted = formatDate(end);
  displayElement.text(`${startFormatted} - ${endFormatted}`);
}

async function loadCSV() {
  if (isMobileDevice()) {
    showMobileMessage();
    return; // Stop execution for mobile devices
  }

  // Show loading element
  document.getElementById("loading").style.display = "flex";
  document.getElementById("brush-container").style.display = "none";
  document.getElementById("search-bar").style.display = "none";
  document.getElementById("color-column").style.display = "none";

  try {
    // Fetch data from your API endpoint
    const response = await fetch("/api/sites");

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();

    // Assuming 'data' is the key that contains the array of customers
    const data = responseData;

    // Continue processing as before
    const data2 = data;
    const { svgNode, points: createdPoints, xScale, yScale, zoom } = run(data2);
    document.body.appendChild(svgNode);
    points = createdPoints; // Assign to global variable
    x = xScale; // Assign to global variable
    y = yScale; // Assign to global variable
    setupBrush(data2, points);
  } catch (error) {
    console.error("There was a problem fetching the data:", error);
  } finally {
    document.getElementById("brush-container").style.display = "block";
    document.getElementById("search-bar").style.display = "block";
    document.getElementById("color-column").style.display = "block";
    // Hide loading element
    document.getElementById("loading").style.display = "none";
  }
}

function run(data) {
  //Popup
  const popup = document.getElementById("popup");
  const slides = document.getElementsByClassName("slide");
  const prevSlideButton = document.getElementById("prevSlide");
  const nextSlideButton = document.getElementById("nextSlide");
  const skipPopupButton = document.getElementById("skipPopup");

  const filterButton = document.getElementById("color-column");

  let currentSlideIndex = 0;

  function showSlide(index) {
    if (index >= slides.length) {
      index = 0;
    } else if (index < 0) {
      index = slides.length - 1;
    }

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = i === index ? "block" : "none";
    }

    currentSlideIndex = index;
  }

  prevSlideButton.addEventListener("click", () => {
    if (currentSlideIndex > 0) {
      showSlide(currentSlideIndex - 1);
    }
  });

  nextSlideButton.addEventListener("click", () => {
    if (currentSlideIndex < 5) {
      showSlide(currentSlideIndex + 1);
    }
  });

  skipPopupButton.addEventListener("click", () => {
    popup.style.display = "none";
    filterButton.style.visibility = "visible";
  });

  popup.addEventListener("click", (event) => {
    if (event.target === popup) {
      popup.style.display = "none";
      filterButton.style.visibility = "visible";
    }
  });

  // Show the popup and the first slide initially
  popup.style.display = "block";
  showSlide(0);

  //Setting Variables
  let width = window.innerWidth;
  let height = window.innerHeight;
  let k = height / width;

  const app = d3.select("#app");
  const svgWrapper = d3.select("#svg-wrapper");

  //Setting up the scales
  const x = d3.scaleLinear().domain([-18, 26]).range([0, width]);
  const y = d3.scaleLinear().domain([-17, 27]).range([height, 0]);

  //Setting up the Side Panel and associated functions
  const sidePanel = app
    .append("div")
    .attr("id", "side-panel") // Assign an ID for CSS styling
    .style("display", "none");

  // Create an information icon button
  const infoButton = app
    .append("button")
    .attr("id", "info-button")
    .text("ℹ")
    .on("click", show);

  // Style for the close button
  const closeButton = sidePanel
    .append("button")
    .attr("id", "close-button")
    .attr("class", "material-symbols-outlined")
    .text("close")
    .on("click", function () {
      sidePanel.style("display", "none");
      infoButton.style("display", "block");
    });

  function show(event) {
    popup.style.display = "block";
    showSlide(0);
  }

  // Create a container for dynamic content
  const dynamicContent = sidePanel
    .append("div")
    .attr("class", "dynamicContent")
    .style("width", "700px");

  function showDotInfo(event, d) {
    dynamicContent
      .html(
        `
              <h2 style="margin-bottom: 10px; font-family: var(--main-font); font-size: 22px;">Information</h2>
              <p><strong>Created At:</strong> ${d.datetime}</p>
              <p><strong>Text:</strong> ${d.Document}</p>
              <p><strong>Topic Area:</strong> ${d.Topic}</p>
              <p><strong>Sentiment:</strong> ${d.Sentiment}</p>
              <p><strong>Offensive:</strong> ${d.Offensive}</p>
              <p><strong>Language:</strong> ${d.Language}</p>
              <p><strong>Emotion:</strong> ${d.Emotion}</p>
              <p><strong>Irony:</strong> ${d.Irony}</p>
              <p><strong>Hate:</strong> ${d.Hate}</p>
            `
      )
      .style("line-height", "1.5")
      .style("font-family", "var(--main-font)")
      .style("font-size", "16px");

    sidePanel.style("display", "block");
  }
  function hideDotInfo() {
    sidePanel.style("display", "none");
    popup.style.display = "none";
  }

  //Setting up the Grid
  grid = (g, x, y) =>
    g
      .attr("stroke", "white")
      .attr("stroke-opacity", 0.16)
      .call((g) =>
        g
          .selectAll(".x")
          .data(x.ticks(12))
          .join(
            (enter) =>
              enter.append("line").attr("class", "x").attr("y2", height),
            (update) => update,
            (exit) => exit.remove()
          )
          .attr("x1", (d) => 0.5 + x(d))
          .attr("x2", (d) => 0.5 + x(d))
      )
      .call((g) =>
        g
          .selectAll(".y")
          .data(y.ticks(12 * k))
          .join(
            (enter) =>
              enter.append("line").attr("class", "y").attr("x2", width),
            (update) => update,
            (exit) => exit.remove()
          )
          .attr("y1", (d) => 0.5 + y(d))
          .attr("y2", (d) => 0.5 + y(d))
      );

  //Setting up the Axes
  xAxis = (g, x) =>
    g
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisTop(x).ticks(12))
      .call((g) => g.select(".domain").attr("display", "none"))
      .call((g) =>
        g
          .selectAll("text")
          .style("font-family", "var(--main-font)")
          .style("font-size", "14px")
      );

  yAxis = (g, y) =>
    g
      .call(d3.axisRight(y).ticks(12 * k))
      .call((g) => g.select(".domain").attr("display", "none"))
      .call((g) =>
        g
          .selectAll("text")
          .style("font-family", "var(--main-font)")
          .style("font-size", "14px")
      );

  const svg = svgWrapper.append("svg").attr("viewBox", [0, 0, width, height]);

  svg.attr("width", "100%").attr("height", "100%");

  const gGrid = svg.append("g");

  //Creating the scatterplot on the grid
  const gDot = svg
    .append("g")
    .attr("fill", "none")
    .attr("stroke-linecap", "round");

  const points = gDot
    .selectAll(".point")
    .enter()
    .data(data)
    .attr("class", "point")
    .join("path")
    .attr("d", (d) => `M${x(d.Embeddings_umap_x)},${y(d.Embeddings_umap_y)}h0`)
    .attr("opacity", 1) // Set initial opacity to 1
    .on("click", showDotInfo);

  const gx = svg.append("g");

  const gy = svg.append("g");

  //Append Colour to Scatterplot
  function updateColors(colorColumn) {
    currentColorColumn = colorColumn;
    let colorScheme;

    // Create a color scale using the custom pastel colors
    if (colorColumn == "Sentiment") {
      colorScheme = d3.scaleOrdinal().range(sentimentColors);
    } else if (colorColumn == "Offensive") {
      colorScheme = d3.scaleOrdinal().range(offensiveColors);
    } else {
      colorScheme = d3.scaleOrdinal().range(customPastelColors);
    }
    points
      .attr("stroke", (d) => colorScheme(d[colorColumn]))
      .attr("data-original-color", (d) => colorScheme(d[colorColumn]));

    applyFilters();
  }

  // Add this function after creating the points
  function filterPoints() {
    currentSearchTerm = d3
      .select("#search-bar")
      .property("value")
      .trim()
      .toLowerCase();
    applyFilters();
  }

  function handleEnterPress(event) {
    if (event.key === "Enter") {
      const searchTerm = d3
        .select("#search-bar")
        .property("value")
        .trim()
        .toLowerCase();
      if (searchTerm && !searchTerms.includes(searchTerm)) {
        searchTerms.push(searchTerm);
        searchColors.push(getUniqueColor());
        addSearchLabel(searchTerm, searchColors[searchColors.length - 1]);
        d3.select("#search-bar").property("value", "");
        currentSearchTerm = ""; // Clear the current search term
      }
      applyFilters();
    }
  }

  function getUniqueColor() {
    const usedColors = new Set(searchColors);
    let color;
    do {
      color =
        customPastelColors[
          Math.floor(Math.random() * customPastelColors.length)
        ];
    } while (usedColors.has(color));
    return color;
  }

  function addSearchLabel(term, color) {
    const labelsContainer = d3.select("#search-labels");
    const label = labelsContainer
      .append("div")
      .attr("class", "search-label")
      .style("background-color", color)
      .style("display", "inline-block")
      .style("margin", "5px")
      .style("padding", "5px 10px")
      .style("color", "#000000")
      .style("font-size", "14px")
      .style("cursor", "default");

    label.append("span").text(term);

    label
      .append("button")
      .attr("class", "remove-label")
      .style("background", "none")
      .style("border", "none")
      .style("color", "#000000")
      .style("margin-left", "5px")
      .style("cursor", "pointer")
      .style("font-weight", "bold")
      .text("×")
      .on("click", () => removeSearchTerm(term));
  }

  function removeSearchTerm(term) {
    const index = searchTerms.indexOf(term);
    if (index > -1) {
      searchTerms.splice(index, 1);
      searchColors.splice(index, 1);
    }
    d3.select("#search-labels")
      .selectAll(".search-label")
      .filter(function () {
        return d3.select(this).select("span").text() === term;
      })
      .remove();
    applyFilters();
  }

  // Add event listener to the search bar
  d3.select("#search-bar")
    .on("input", filterPoints)
    .on("keyup", handleEnterPress);
  d3.select("#color-column").on("change", function () {
    updateColors(this.value);
    filterPoints();
  });
  window.addEventListener("resize", onWindowResize);

  // Create a filter view
  const buttonView = app.select("#color-column").on("change", function () {
    updateColors(this.value);
    filterPoints();
  });

  // Initial coloring by column 'z'
  updateColors("Topic");
  applyFilters();

  //Setting up the zoom
  let currentTransform = d3.zoomIdentity;

  const zoom = d3.zoom().scaleExtent([1, 32]).on("zoom", zoomed);

  svg.call(zoom).call(zoom.transform, d3.zoomIdentity);

  function zoomed({ transform }) {
    const zx = transform.rescaleX(x).interpolate(d3.interpolateRound);
    const zy = transform.rescaleY(y).interpolate(d3.interpolateRound);
    gDot.attr("transform", transform).attr("stroke-width", 5 / transform.k);
    currentTransform = transform;
    gx.call(xAxis, zx);
    gy.call(yAxis, zy);
    gGrid.call(grid, zx, zy);
  }

  //Setting up the resize
  function onWindowResize() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const k = height / width;

    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);

    x.range([0, width]);
    y.range([height, 0]);

    zx = currentTransform.rescaleX(x).interpolate(d3.interpolateRound);
    zy = currentTransform.rescaleY(y).interpolate(d3.interpolateRound);

    gx.call(xAxis, zx).attr("transform", `translate(0,${height})`);
    gy.call(yAxis, zy);

    gGrid.selectAll(".x").attr("y2", height);
    gGrid.selectAll(".y").attr("x2", width);
    gGrid.call(grid, zx, zy);

    svg.call(zoom.transform, currentTransform);

    applyFilters();
  }

  window.addEventListener("resize", onWindowResize);

  return {
    svgNode: svg.node(),
    points: points,
    xScale: x,
    yScale: y,
    zoom: zoom,
  };
}

function setupBrush(data) {
  const brushContainer = d3.select("#brush-container");
  const containerWidth = 50;
  const containerHeight = window.innerHeight * 0.6;

  const margin = { top: 5, right: 7, bottom: 7, left: 5 };
  const width = containerWidth - margin.left - margin.right;
  const height = containerHeight - margin.top - margin.bottom;

  const parseDate = d3.isoParse;
  const dates = data.map((d) => parseDate(d.datetime));
  const minDate = d3.min(dates);
  const maxDate = d3.max(dates);

  timeScale = d3.scaleTime().domain([minDate, maxDate]).range([0, height]);

  const brush = d3
    .brushY()
    .extent([
      [0, 0],
      [width, height],
    ])
    .on("brush", brushed);

  const svg = brushContainer
    .html("")
    .append("svg")
    .attr("width", containerWidth)
    .attr("height", containerHeight)
    .style("background", "transparent");

  // Add a group for the brush
  const brushGroup = svg
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`)
    .attr("class", "brush")
    .call(brush);

  // Customize the brush selection appearance
  brushGroup
    .select(".selection")
    .attr("fill", "rgba(105, 179, 162, 0.4)")
    .attr("stroke", "rgba(105, 179, 162, 0.6)")
    .attr("stroke-width", 1);

  brushGroup.call(brush.move, timeScale.range());

  function brushed(event) {
    if (!event.selection) {
      currentDateRange = { start: minDate, end: maxDate };
    } else {
      const [y0, y1] = event.selection.map(timeScale.invert);
      currentDateRange = { start: y0, end: y1 };
    }
    updateDateRangeDisplay(currentDateRange.start, currentDateRange.end);
    applyFilters();
  }

  // Initial date range display
  updateDateRangeDisplay(minDate, maxDate);
}

function filterPointsByDateRange(startDate, endDate) {
  if (!points) return;

  points.each(function (d) {
    const date = d3.isoParse(d.datetime);
    const withinRange = date >= startDate && date <= endDate;

    d3.select(this)
      .attr(
        "stroke",
        withinRange ? d3.select(this).attr("data-original-color") : "#1a1a1a"
      )
      .attr("d", (d) =>
        withinRange
          ? `M${x(d.Embeddings_umap_x)},${y(d.Embeddings_umap_y)}h0`
          : ""
      ); // Hide points outside the range
  });
}

function applyFilters() {
  if (!points) return;

  points.each(function (d) {
    const date = d3.isoParse(d.datetime);
    const withinDateRange =
      (!currentDateRange.start || date >= currentDateRange.start) &&
      (!currentDateRange.end || date <= currentDateRange.end);

    const matchesCurrentSearch =
      currentSearchTerm === "" ||
      d.Document.toLowerCase().includes(currentSearchTerm);

    let matchesLabeledSearch = searchTerms.length === 0;
    let matchColor = null;
    for (let i = 0; i < searchTerms.length; i++) {
      if (d.Document.toLowerCase().includes(searchTerms[i])) {
        matchesLabeledSearch = true;
        matchColor = searchColors[i];
        break;
      }
    }

    const visible = withinDateRange && matchesCurrentSearch;

    d3.select(this)
      .attr(
        "stroke",
        visible
          ? matchColor || d3.select(this).attr("data-original-color")
          : "#1a1a1a"
      )
      .attr(
        "d",
        visible ? `M${x(d.Embeddings_umap_x)},${y(d.Embeddings_umap_y)}h0` : ""
      );

    // If there are labeled search terms, make non-matching points white
    if (searchTerms.length > 0 && visible && !matchesLabeledSearch) {
      d3.select(this).attr("stroke", "white");
    }
  });
}

loadCSV().then(() => {
  setupBrush(data);
  applyFilters();
});
